import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';

function Index() {
  const [date, setDate] = useState(null);

  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);

  return (
    <main className="fade container">
      <Helmet>
        <title>Catherine Feldman</title>
      </Helmet>

      <a href="#" class="identAC">Catherine Feldman</a>
      <h1>Catherine Feldman</h1>
      <p>
        Quantifying social constructs with mathematical concepts to create the future of connection in digital spaces
      </p>
      <p>
        Bringing people together unbounded by space & time
      </p>
      <p>
        Senior Program Manager for Connected Media @ <a href="https://www.microsoft.com/" target="_blank">Microsoft</a>
      </p>
      <p style={{ marginBottom: 0 }}>
        Let's connect
      </p>
      <ul class="social--links">
        <li><a href="mailto:annecatherine.feldman@gmail.com" class="one" target="_blank">annecatherine.feldman@gmail.com</a></li>
        <li><a href="https://www.linkedin.com/in/annecatherinefeldman/" class="two" target="_blank">linkedin</a></li>
        <li><a href="https://www.facebook.com/catherine.feldman/" class="two" target="_blank">facebook</a></li>
      </ul>
    </main>
  );
}

export default Index;
